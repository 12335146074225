<template>
    <section id="artikel">
        <appSideNavigation></appSideNavigation>
        <div id="nav">
            <router-link to="/artikelliste">Artikelliste</router-link> 
        </div>
        <h1>Artikel</h1>
        {{this.$route.params.name}}:  {{this.$route.params.id}}
    </section>
</template>

<script>
import SideNavigation from '../components/SideNavigation.vue'
import Util from '../mixins/util'

export default{
    mounted: function () {
        this.navigateDependingOnSession()
    },
    components: {
        appSideNavigation: SideNavigation
    },
    mixin: [Util]
};
</script>
